import React, { useEffect, useRef, useState } from 'react';
const OwlCarousel = React.lazy(() => import('react-owl-carousel'));
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

let containerName = 'heroprd';
const url = process.env.REACT_APP_MARKETPLACE_ROOT_URL;
if(url.includes('localhost') || url.includes('dev') || url.includes('test')) {
  containerName = 'herodevtest';
}

const hero1 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group01-Image01.jpg`;
const hero2 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group01-Image02.jpg`;
const hero3 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group01-Image03.jpg`;
const hero4 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group01-Image04.jpg`;
const hero5 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group02-Image01.jpg`;
const hero6 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group02-Image02.jpg`;
const hero7 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group02-Image03.jpg`;
const hero8 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group02-Image04.jpg`;
const hero10 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group03-Image01.jpg`;
const hero11 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group03-Image02.jpg`;
const hero12 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group03-Image03.jpg`;
const hero13 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group03-Image04.jpg`;
const hero14 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group04-Image01.jpg`;
const hero15 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group04-Image02.jpg`;
const hero16 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group04-Image03.jpg`;
const hero17 = `https://eeventstorage.blob.core.windows.net/${containerName}/banner17.jpg`;
const hero18 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group04-Image04.jpg`;
const hero19 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group05-Image01.jpg`;
const hero20 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group05-Image02.jpg`;
const hero21 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group05-Image03.jpg`;
const hero22 = `https://eeventstorage.blob.core.windows.net/${containerName}/banner22.jpg`;
const hero23 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group05-Image04.jpg`;
const hero24 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group06-Image01.jpg`;
const hero25 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group06-Image02.jpg`;
const hero26 = `https://eeventstorage.blob.core.windows.net/${containerName}/banner26.jpg`;
const hero27 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group06-Image03.jpg`;
const hero28 = `https://eeventstorage.blob.core.windows.net/${containerName}/banner28.jpg`;
const hero29 = `https://eeventstorage.blob.core.windows.net/${containerName}/banner29.jpg`;
const hero30 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group06-Image04.jpg`;
const hero31 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group07-Image01.jpg`;
const hero32 = `https://eeventstorage.blob.core.windows.net/${containerName}/banner32.jpg`;
const hero33 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group07-Image02.jpg`;
const hero34 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group07-Image03.jpg`;
const hero35 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group07-Image04.jpg`;
const hero36 = `https://eeventstorage.blob.core.windows.net/${containerName}/Desktop-Group07-Image05.jpg`;
const hero1XS = `https://eeventstorage.blob.core.windows.net/${containerName}/Mobile-Image01.jpg`;
const hero2XS = `https://eeventstorage.blob.core.windows.net/${containerName}/Mobile-Image02.jpg`;
const hero3XS = `https://eeventstorage.blob.core.windows.net/${containerName}/Mobile-Image03.jpg`;
const hero4XS = `https://eeventstorage.blob.core.windows.net/${containerName}/Mobile-Image04.jpg`; 
import searchIcon from './img/search.png';
import locationIcon from './img/location.svg';
import mobilesearchicon from './img/search.svg';
import css from './SectionHero.module.css';
import { useConfiguration } from '../../../../context/configurationContext';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { useIntl } from 'react-intl';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../../../util/search';
import TopbarSearchForm from '../../../TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';
import { createResourceLocatorString } from '../../../../util/routes';

import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { NamedLink } from '../../../../components';
import { Affix, Collapse } from 'antd';
import {
  ArrowRightOutlined,
  UserOutlined,
} from '@ant-design/icons';
const SectionHero = (props) => {

  const heroOptions = {
    items: 1,
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    animateOut: 'fadeOut'
  };

  const items = [
    {
      key: '1',
      label: 'C',
      children: <p>ddddd</p>,
    },
  ];

  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    // Ensure this code only runs on the client
    setIsClient(true);
  }, []);

  const { currentSearchParams } = props;

  const history = useHistory();
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();

  // Function to initialize search form values
  const topbarSearchInitialValues = () => {
    const { address, origin, bounds } = props; // Ensure these values are passed as props or otherwise available
    if (isMainSearchTypeKeywords(config)) {
      return { keywords: keywords };
    }

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = isOriginInUse(config)
      ? address && origin && bounds
      : address && bounds;

    return {
      location: locationFieldsPresent
        ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
        : null,
    };
  };

  const initialSearchFormValues = topbarSearchInitialValues();

  const [location, setSearchLocation] = useState(null);
  const [keywords, setKeyword] = useState(null);
  const inputRef2 = useRef(null);
  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };
  const handleDropdownChange = (e) => {
    inputRef2.current.focus();
    setSearchLocation(e.location);
    
  };

  const handleSubmit = (values) => {
    const topbarSearchParams = () => {
      if (values?.keywords && !(values?.location)) {
        return { keywords: values?.keywords };
      }

      // Topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location?.location || values?.location || {};
      const { origin, bounds } = selectedPlace || {};
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      if (values?.keywords) {
        return {
          ...originMaybe,
          address: search,
          bounds,
          keywords: values?.keywords
        };
      }
      else {
        return {
          ...originMaybe,
          address: search,
          bounds
        }
      }
    };

    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  const [top] = React.useState(0);
  const currentPage = 'LandingPage';


  const contentArray = [
    <>
      <img src={hero1} alt="Hero" className={css.backgroundImage} />
      <img src={hero2} alt="Hero" className={css.backgroundImage} />
      <img src={hero3} alt="Hero" className={css.backgroundImage} />
      <img src={hero4} alt="Hero" className={css.backgroundImage} />
    </>,
    <>
      <img src={hero5} alt="Hero" className={css.backgroundImage} />
      <img src={hero6} alt="Hero" className={css.backgroundImage} />
      <img src={hero7} alt="Hero" className={css.backgroundImage} />
      <img src={hero8} alt="Hero" className={css.backgroundImage} />
    </>,
    <>
      <img src={hero10} alt="Hero" className={css.backgroundImage} />
      <img src={hero11} alt="Hero" className={css.backgroundImage} />
      <img src={hero12} alt="Hero" className={css.backgroundImage} />
      <img src={hero13} alt="Hero" className={css.backgroundImage} />
    </>,
    <>

      <img src={hero14} alt="Hero" className={css.backgroundImage} />
      <img src={hero15} alt="Hero" className={css.backgroundImage} />
      <img src={hero16} alt="Hero" className={css.backgroundImage} />
      <img src={hero18} alt="Hero" className={css.backgroundImage} />
    </>,
    <>
      {/*<img src={hero17} alt="Hero" className={css.backgroundImage} />*/}

      <img src={hero19} alt="Hero" className={css.backgroundImage} />
      <img src={hero20} alt="Hero" className={css.backgroundImage} />
      <img src={hero21} alt="Hero" className={css.backgroundImage} />
      {/*<img src={hero22} alt="Hero" className={css.backgroundImage} />*/}
      <img src={hero23} alt="Hero" className={css.backgroundImage} />
    </>,
    <>
      <img src={hero24} alt="Hero" className={css.backgroundImage} />
      <img src={hero25} alt="Hero" className={css.backgroundImage} />
      {/*<img src={hero26} alt="Hero" className={css.backgroundImage} />*/}
      <img src={hero27} alt="Hero" className={css.backgroundImage} />
      <img src={hero30} alt="Hero" className={css.backgroundImage} />
      {/*<img src={hero28} alt="Hero" className={css.backgroundImage} />*/}
    </>,
    <>
      {/*<img src={hero29} alt="Hero" className={css.backgroundImage} />*/}

      <img src={hero31} alt="Hero" className={css.backgroundImage} />
      <img src={hero33} alt="Hero" className={css.backgroundImage} />
      <img src={hero34} alt="Hero" className={css.backgroundImage} />
      <img src={hero35} alt="Hero" className={css.backgroundImage} />
      <img src={hero36} alt="Hero" className={css.backgroundImage} />
      {/*<img src={hero32} alt="Hero" className={css.backgroundImage} />*/}
    </>,

  ];
  
  const contentArrayMobile = [
    <>
      <img src={hero1XS} alt="Hero"/>
    </>,
    <>
      <img src={hero2XS} alt="Hero" />
    </>,
    <>
      <img src={hero3XS} alt="Hero" />
    </>,
    <>
      <img src={hero4XS} alt="Hero" />
    </>
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    // Get the last displayed index from localStorage
    const lastIndex = parseInt(localStorage.getItem('currentIndex')) || 0;
    const nextIndex = (lastIndex + 1) % contentArray.length; // Move to the next index
    setCurrentIndex(nextIndex);
    localStorage.setItem('currentIndex', nextIndex); // Update the index in localStorage
    
  }, []);
  
  const [currentIndexMobile, setCurrentIndexMobile] = useState(0);
  useEffect(() => {
    // Get the last displayed index from localStorage
    const lastIndexMobile = parseInt(localStorage.getItem('currentIndexMobile')) || 0;
    const nextIndexMobile = (lastIndexMobile + 1) % contentArrayMobile.length; // Move to the next index
    setCurrentIndexMobile(nextIndexMobile);
    localStorage.setItem('currentIndexMobile', nextIndexMobile); // Update the index in localStorage
    
  }, []);
  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && (location || keywords)) {
      handleSubmit({ location, keywords }); // Call your submit function
    }
  };  
  const handleKeyDown2 = (event) => {
    let mobileLocation = event.location;
    let mobileKeyword = keywords;
    if ( mobileLocation || mobileKeyword) {
      handleSubmit({ location:mobileLocation, keywords:mobileKeyword }); // Call your submit function
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const onChange=(e,location) =>{
    if (e.key === 'Enter' && (location || keywords)) {
      handleSubmit({ location, keywords });
    }
  }
  return (

    <div className={css.heroSliderBlock}>
      <div style={{ minHeight: '74vh' }} className={css.VisibleDesktop}>

        <div
          className={css.hero}
          id="top"
        >
          <div className={css.HeroContainer}>



            {isClient && (
              <React.Suspense fallback={<div  className={css.VisibleDesktop} style={{ height: '69vh' }}>
                
                <img src={contentArray[currentIndex].props.children[0].props.src} className={css.backgroundImage} />
                <div className={css.overlay}>
                  <div className={css.buttonStack}>
                    <NamedLink name="SearchPage" >
                      <button type="button" className={css.whiteButton}>
                        Explore our Services
                      </button>
                    </NamedLink>
                    {/* Commenting this section for 5th October launch  */}
                    {/* <button type="button" className={css.transparentButton}>Request Bids</button> */}
                  </div>
                  <Affix offsetTop={top} className={css.searchAffix}>

                    <div className={css.searchBox}
                      onKeyDown={(e) => onChange(e, location)} >
                      <div className={css.formLocation} >
                        <label htmlFor="Location">Location</label>
                        <TopbarSearchForm
                          className={css.formControl}
                          onSubmit={handleDropdownChange}
                          initialValues={initialSearchFormValues}
                          appConfig={config}
                          currentPage={currentPage}
                          currentSearchParams={currentSearchParams}
                          routeConfiguration={routeConfiguration}
                          history={history}
                        />
                      </div>

                      <div>
                        <label htmlFor="Keyword">Keyword</label>
                        <input
                          type="text"
                          className={css.formControl}
                          id="Keyword"
                          placeholder="Type Events or Services"
                          onChange={handleKeywordChange}
                          onKeyDown={handleKeyDown}
                        />
                      </div>

                      <div>
                        <button
                          type="button"
                          className={css.primaryButton}
                          onClick={() => handleSubmit({ location, keywords })}
                        >
                          <span><img src={searchIcon} alt="Search Icon" /></span>
                          Search
                        </button>
                      </div>
                    </div>
                  </Affix>
                </div>
                
                </div>}>
                <div className={css.VisibleDesktop}>
                  <OwlCarousel  {...heroOptions}>
                    {contentArray[currentIndex]}
                  </OwlCarousel>
                </div>
                <div className={css.VisibleMobile}>
                  <OwlCarousel  {...heroOptions}>
                    <img src={hero1XS} alt="Hero" className={css.backgroundImage} />
                    <img src={hero2XS} alt="Hero" className={css.backgroundImage} />
                    <img src={hero3XS} alt="Hero" className={css.backgroundImage} />
                    <img src={hero4XS} alt="Hero" className={css.backgroundImage} />
                  </OwlCarousel>
                </div>
                <div className={css.overlay}>
              <div className={css.buttonStack}>
                <NamedLink name="SearchPage" >
                  <button type="button" className={css.whiteButton}>
                    Explore our Services
                  </button>
                </NamedLink>
                {/* Commenting this section for 5th October launch  */}
                {/* <button type="button" className={css.transparentButton}>Request Bids</button> */}
              </div>
              <Affix offsetTop={top} className={css.searchAffix}>

                <div className={css.searchBox}
                  onKeyDown={(e)=>onChange(e,location)} >
                  <div className={css.formLocation} >
                    <label htmlFor="Location">Location</label>
                    <TopbarSearchForm
                      className={css.formControl}
                      onSubmit={handleDropdownChange}
                      initialValues={initialSearchFormValues}
                      appConfig={config}
                      currentPage={currentPage}
                      currentSearchParams={currentSearchParams}
                      routeConfiguration={routeConfiguration}
                      history={history}
                    />
                  </div>

          <div>
            <label htmlFor="Keyword">Keyword</label>
            <input
              type="text"
              className={css.formControl}
              id="Keyword"
              placeholder="Type Events or Services"
              onChange={handleKeywordChange}
              onKeyDown={handleKeyDown}
              ref={inputRef2} 
            />
          </div>

                  <div>
                    <button
                      type="button"
                      className={css.primaryButton}
                      onClick={() => handleSubmit({ location, keywords })}
                    >
                      <span><img src={searchIcon} alt="Search Icon" /></span>
                      Search
                    </button>
                  </div>


                </div>
              </Affix>

            </div>
              </React.Suspense>
            )}


           
          </div>
        </div>

      </div>
      <div className={css.VisibleMobile}>
        <div className={css.mobileContainer}>
          {/* <h2>Find your next perfect event</h2> */}
          
        <div className={css.mobileHero}>
          {contentArrayMobile[currentIndexMobile]}
          <NamedLink name="SearchPage" >
            <button type="button" className={css.primaryButtonMobile}>
              Explore our Services <ArrowRightOutlined className={css.btnIcon} />
            </button>
          </NamedLink>
          </div>


          <div className={css.mobileSearch}>
            <div className={css.keywordSearch}>

              <span><img src={mobilesearchicon} alt="Search Icon" /></span>

              <input
              type="text"
              className={css.formControl}
              id="Keyword"
              placeholder="Type Events or Services"
              onChange={handleKeywordChange}
              onKeyDown={handleKeyDown}
            />
            </div>
            <button className={css.locationBtn} onClick={toggleVisibility}>
              <span><img src={locationIcon} alt="Location Icon" /></span>
            </button>

          </div>
          {isVisible && (
          <TopbarSearchForm
              className={css.locationInput}
              onSubmit={handleKeyDown2}
              initialValues={initialSearchFormValues}
              appConfig={config}
              currentPage={currentPage}
              currentSearchParams={currentSearchParams}
              routeConfiguration={routeConfiguration}
              history={history}
            />
          )}


        </div>
      </div>

    </div>

  );
};

export default SectionHero;
