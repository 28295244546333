import React, { useEffect, useState } from 'react';

const OwlCarousel = React.lazy(() => import('react-owl-carousel'));
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

let containerName = 'perfectprd';
const url = process.env.REACT_APP_MARKETPLACE_ROOT_URL;
if(url.includes('localhost') || url.includes('dev') || url.includes('test')) {
  containerName = 'perfectdevtest';
}

const Reunion = `https://eeventstorage.blob.core.windows.net/${containerName}/Reunion.jpg`;
const Formals = `https://eeventstorage.blob.core.windows.net/${containerName}/Formals.jpg`;
const HensNight = `https://eeventstorage.blob.core.windows.net/${containerName}/HensNight.jpg`;
const BucksNight = `https://eeventstorage.blob.core.windows.net/${containerName}/BucksNight.jpg`;
const NamingCeremony = `https://eeventstorage.blob.core.windows.net/${containerName}/NamingCeremony.jpg`;
const CommitmentCeremony = `https://eeventstorage.blob.core.windows.net/${containerName}/CommitmentCeremony.jpg`;
const TradeShow = `https://eeventstorage.blob.core.windows.net/${containerName}/TradeShow.jpg`;
const CharityEvent = `https://eeventstorage.blob.core.windows.net/${containerName}/CharityEvent.jpg`;
const VIPReception = `https://eeventstorage.blob.core.windows.net/${containerName}/VIPReception.jpg`;
const PrivateDinner = `https://eeventstorage.blob.core.windows.net/${containerName}/PrivateDinner.jpg`;
const TeamBuilding = `https://eeventstorage.blob.core.windows.net/${containerName}/TeamBuilding.jpg`;
const FamilyFunDay = `https://eeventstorage.blob.core.windows.net/${containerName}/FamilyFunDay.jpg`;
const HennaParty = `https://eeventstorage.blob.core.windows.net/${containerName}/HennaParty.jpg`;
const JustGetTogether = `https://eeventstorage.blob.core.windows.net/${containerName}/JustGetTogether.jpg`;
const Engagement = `https://eeventstorage.blob.core.windows.net/${containerName}/Engagement.jpg`;
const Wedding = `https://eeventstorage.blob.core.windows.net/${containerName}/Wedding.jpg`;
const Marriage = `https://eeventstorage.blob.core.windows.net/${containerName}/Marriage.jpg`;
const BabyShower = `https://eeventstorage.blob.core.windows.net/${containerName}/BabyShower.jpg`;
const Retirement = `https://eeventstorage.blob.core.windows.net/${containerName}/Retirement.jpg`;
const HouseWarming = `https://eeventstorage.blob.core.windows.net/${containerName}/HouseWarming.jpg`;
const CocktailParty = `https://eeventstorage.blob.core.windows.net/${containerName}/CocktailParty.jpg`;
const CorporateEvent = `https://eeventstorage.blob.core.windows.net/${containerName}/CorporateEvent.jpg`;
const Religious = `https://eeventstorage.blob.core.windows.net/${containerName}/Religious.jpg`;
const BachelorParty = `https://eeventstorage.blob.core.windows.net/${containerName}/BachelorParty.jpg`;
const NewYear = `https://eeventstorage.blob.core.windows.net/${containerName}/NewYear.jpg`;
const WeddingReception = `https://eeventstorage.blob.core.windows.net/${containerName}/WeddingReception.jpg`;
const Birthday = `https://eeventstorage.blob.core.windows.net/${containerName}/Birthday.jpg`;
const BacheloretteParty = `https://eeventstorage.blob.core.windows.net/${containerName}/BacheloretteParty.jpg`;
const Halloween = `https://eeventstorage.blob.core.windows.net/${containerName}/Halloween.jpg`;
const BridalShower = `https://eeventstorage.blob.core.windows.net/${containerName}/BridalShower.jpg`;
const Graduation = `https://eeventstorage.blob.core.windows.net/${containerName}/Graduation.jpg`;
const Anniversary = `https://eeventstorage.blob.core.windows.net/${containerName}/Anniversary.jpg`;
const Christmas = `https://eeventstorage.blob.core.windows.net/${containerName}/Christmas.jpg`;
const Photoshoot = `https://eeventstorage.blob.core.windows.net/${containerName}/Photoshoot.jpg`;
const Festival = `https://eeventstorage.blob.core.windows.net/${containerName}/Festival.jpg`;

import rightArrow from './img/right-arrow.png';
import userfriendly from './img/userfriendly.png';
import seemlessbooking from './img/seemlessbooking.png';
import multicultural from './img/multicultural.png';
import { ArrowRightOutlined } from '@ant-design/icons';
import css from './SectionTopBookedListings.module.css';
import { NamedLink } from '../../../../components';
// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionTopBookedListings = props => {

  const alleventOptions = {
    center: true,
    loop: true,
    margin: 30,
    nav: false,
    dots: false,
    autoWidth: true,
    autoplay: true,
    //slideTransition: 'linear',
    autoplayTimeout: 4000,
    autoplaySpeed: 3000
  }

  const recentEventOptions = {
    responsive: {
      550: {
        items: 2,
        slideBy: 2,
      },
      700: {
        items: 2,
        margin: 0,
        slideBy: 2,
      },
      1000: {
        items: 5,
        slideBy: 5,
      },
      1400: {
        items: 5,
        slideBy: 5,
      }
    },
    items: 1,
    slideBy: 1,
    className: "owl-recent",
    loop: false,
    stagePadding: 0,
    nav: true,
    autoplay: false,
    dots: false,
    margin: 5,
    navText: ['<svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path></svg>', '<svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path></svg>']
  }

  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    // Ensure this code only runs on the client
    setIsClient(true);
  }, []);

  return (

    <div className={css.service}>
      <div className={css.marquee}>
        {isClient && (
          <React.Suspense fallback={<div>Loading carousel...</div>}>
            <OwlCarousel  {...alleventOptions}>
              <span>Wedding</span>    <span>Marriage</span>    <span>Birthday</span>    <span>Graduation</span>   <span>Anniversary</span>    <span>Engagement</span>

            </OwlCarousel>
          </React.Suspense>
        )}
      </div>


      <div className={css.container}>

        <div className={css.dFlex}>
          <h3>Find Services For Your <span>Perfect Event</span></h3>
          <span className={css.VisibleDesktop}><NamedLink name="SearchPage">Discover All Events’ Services <img src={rightArrow} alt="" /></NamedLink></span>
        </div>
        <div className={css.innerContainer}>
          <div className={css.slider}>
            {isClient && (
              <React.Suspense fallback={<div>Loading carousel...</div>}>
                <OwlCarousel  {...recentEventOptions}>

                <div className={css.eventCardHolder}>
                <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Anaming-ceremony' }} className={css.image}>
                      <img src={NamingCeremony} alt="Naming Ceremony" className={css.image} />
                      <h5>Naming Ceremony</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Ateam-building' }} className={css.image}>
                      <img src={TeamBuilding} alt="Team Building" className={css.image} />
                      <h5>Team Building</h5>
                    </NamedLink>
                  </div>
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Areunion' }} className={css.image}>
                      <img src={Reunion} alt="Reunion" className={css.image} />
                      <h5>Reunion</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Aformals' }} className={css.image}>
                      <img src={Formals} alt="Formals" className={css.image} />
                      <h5>Formals</h5>
                    </NamedLink>
                  </div>
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Acocktail-party' }} className={css.image}>
                      <img src={CocktailParty} alt="Cocktail Party" className={css.image} />
                      <h5>Cocktail Party</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Ahens-night' }} className={css.image}>
                      <img src={HensNight} alt="Hens Night" className={css.image} />
                      <h5>Hen's Night</h5>
                    </NamedLink>
                  </div>
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Abucks-night' }} className={css.image}>
                      <img src={BucksNight} alt="Bucks Night" className={css.image} />
                      <h5>Buck's Night</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Acommitment-ceremony' }} className={css.image}>
                      <img src={CommitmentCeremony} alt="Commitment Ceremony" className={css.image} />
                      <h5>Commitment Ceremony</h5>
                    </NamedLink>
                  </div>
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Atrade-show' }} className={css.image}>
                      <img src={TradeShow} alt="Trade Show" className={css.image} />
                      <h5>Trade Show</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Acharity-event' }} className={css.image}>
                      <img src={CharityEvent} alt="Charity Event" className={css.image} />
                      <h5>Charity Event</h5>
                    </NamedLink>
                  </div>
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Avip-reception' }} className={css.image}>
                      <img src={VIPReception} alt="VIP Reception" className={css.image} />
                      <h5>VIP Reception</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Aprivate-dinner' }} className={css.image}>
                      <img src={PrivateDinner} alt="Private Dinner" className={css.image} />
                      <h5>Private Dinner</h5>
                    </NamedLink>
                  </div>
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Afamily-fun-day' }} className={css.image}>
                      <img src={FamilyFunDay} alt="Family Fun Day" className={css.image} />
                      <h5>Family Fun Day</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Ahenna-party' }} className={css.image}>
                      <img src={HennaParty} alt="Henna Party" className={css.image} />
                      <h5>Henna Party</h5>
                    </NamedLink>
                  </div>
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Ajust-get-together' }} className={css.image}>
                      <img src={JustGetTogether} alt="Just Get Together" className={css.image} />
                      <h5>Just Get Together</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Aengagement' }} className={css.image}>
                      <img src={Engagement} alt="Engagement" className={css.image} />
                      <h5>Engagement</h5>
                    </NamedLink>
                  </div>
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Awedding' }} className={css.image}>
                      <img src={Wedding} alt="Wedding" className={css.image} />
                      <h5>Wedding</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Amarriage' }} className={css.image}>
                      <img src={Marriage} alt="Marriage" className={css.image} />
                      <h5>Marriage</h5>
                    </NamedLink>
                  </div>
                  </div>
                  <div className={css.eventCardHolder}>
                    <div className={css.eventCard}>
                      <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Ababy-shower' }} className={css.image}>
                        <img src={BabyShower} alt="Baby Shower" className={css.image} />
                        <h5>Baby Shower</h5>
                      </NamedLink>
                    </div>       <div className={css.eventCard}>
                      <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Afestival' }} className={css.image}>
                        <img src={Festival} alt="Festival" className={css.image} />
                        <h5>Festival</h5>
                      </NamedLink>
                    </div>
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Aretirement' }} className={css.image}>
                      <img src={Retirement} alt="Retirement" className={css.image} />
                      <h5>Retirement</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Ahouse-warming' }} className={css.image}>
                      <img src={HouseWarming} alt="House Warming" className={css.image} />
                      <h5>House Warming</h5>
                    </NamedLink>
                  </div>
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Acorporate-event' }} className={css.image}>
                      <img src={CorporateEvent} alt="Corporate Event" className={css.image} />
                      <h5>Corporate Event</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Areligious' }} className={css.image}>
                      <img src={Religious} alt="Religious" className={css.image} />
                      <h5>Religious</h5>
                    </NamedLink>
                  </div>
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Abachelor-party' }} className={css.image}>
                      <img src={BachelorParty} alt="Bachelor Party" className={css.image} />
                      <h5>Bachelor Party</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Anew-year' }} className={css.image}>
                      <img src={NewYear} alt="New Year" className={css.image} />
                      <h5>New Year</h5>
                    </NamedLink>
                  </div> 
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Awedding-reception' }} className={css.image}>
                      <img src={WeddingReception} alt="Wedding Reception" className={css.image} />
                      <h5>Wedding Reception</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Abirthday' }} className={css.image}>
                      <img src={Birthday} alt="Birthday" className={css.image} />
                      <h5>Birthday</h5>
                    </NamedLink>
                  </div>
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Abachelorette-party' }} className={css.image}>
                      <img src={BacheloretteParty} alt="Bachelorette Party" className={css.image} />
                      <h5>Bachelorette Party</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Ahalloween' }} className={css.image}>
                      <img src={Halloween} alt="Halloween" className={css.image} />
                      <h5>Halloween</h5>
                    </NamedLink>
                  </div>
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Abridal-shower' }} className={css.image}>
                      <img src={BridalShower} alt="Bridal Shower" className={css.image} />
                      <h5>Bridal Shower</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Agraduation' }} className={css.image}>
                      <img src={Graduation} alt="Graduation" className={css.image} />
                      <h5>Graduation</h5>
                    </NamedLink>
                  </div>
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Aanniversary' }} className={css.image}>
                      <img src={Anniversary} alt="Anniversary" className={css.image} />
                      <h5>Anniversary</h5>
                    </NamedLink>
                  </div>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Achristmas' }} className={css.image}>
                      <img src={Christmas} alt="Christmas" className={css.image} />
                      <h5>Christmas</h5>
                    </NamedLink>
                  </div>
                  </div>
                  <div className={css.eventCardHolder}>

                  <div className={css.eventCard}>
                    <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Aphotoshoot' }} className={css.image}>
                      <img src={Photoshoot} alt="Photoshoot" className={css.image} />
                      <h5>Photoshoot</h5>
                    </NamedLink>
                  </div>           
                  </div>

                </OwlCarousel>
              </React.Suspense>
            )}
          </div>

          <div className={css.eventCardMobile}>
            <div className={css.eventCard}>
              <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Awedding-reception' }} className={css.image}>
                <img src={WeddingReception} alt="Wedding
          Reception" className={css.image} />
                <h5>Wedding
                  Reception</h5>
              </NamedLink>
            </div>

            <div className={css.eventCard}>
              <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Abirthday' }} className={css.image}>
                <img src={Birthday} alt="Birthday" className={css.image} />
                <h5>Birthday</h5>
              </NamedLink>
            </div>

            <div className={css.eventCard}>
              <NamedLink name="SearchPage" to={{ search: '?pub_events=has_all%3Abachelorette-party' }} className={css.image}>
                <img src={BacheloretteParty} alt="Bachelorette party" className={css.image} />
                <h5>Bachelorette party</h5>
              </NamedLink>
            </div>
            <div className={css.eventCardLink}>

              <NamedLink name="SearchPage" >Discover All Events’ Services <img src={rightArrow} alt="" /></NamedLink>
            </div>
          </div>

        </div>

      </div>

      <div className={css.howDifferent}>
        <div><h5>How is Eevent.com.au different?</h5></div>
        <div>
          <img src={userfriendly} alt="User-Friendly Platform" />
          <h4>User-Friendly Platform</h4>
          <p>Our platform is designed for easy navigation, allowing you to find the perfect
            service providers for your event effortlessly.</p>
        </div>
        <div>
          <img src={seemlessbooking} alt="User-Friendly Platform" />
          <h4>Seamless Booking</h4>
          <p>Unlike some platforms, Eevent offers a streamlined booking system, allowing
            customers to book services directly through the platform.</p>
        </div>
        <div>
          <img src={multicultural} alt="User-Friendly Platform" />
          <h4>Multicultural Focus</h4>
          <p>Eevent celebrates Australia's multicultural diversity by offering a wide range of
            providers & services catering to various cultural traditions and preferences.</p>
        </div>
      </div>

    </div>
  );
};


export default SectionTopBookedListings;
